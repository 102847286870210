<template>
  <div class="mb-3">
    <div class="d-flex justify--center mt-3" v-if="showAlert">
      <span style="color: red;">Login Failed!</span>
    </div>
  </div>
  <form @submit.prevent="onsubmit">
    <va-input
      class="mb-3"
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      class="mb-3"
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="d-flex justify--center mt-3">
      <va-button @click="onsubmit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>

    <!-- TODO: Reset password
    <div class="d-flex justify--center mt-3">
      <router-link class="ml-1 link" :to="{name: 'recover-password'}">{{$t('auth.recover_password')}}</router-link>
    </div>
    -->
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'login',
  mounted () {
    // Remove auth settings on logout
    this.$store.commit('removeToken')
    this.$store.commit('changeUserName', null)
    this.$store.commit('removeUID')
    this.$store.commit('removeAccess')
    this.$store.commit('setSelectedItems', [])
  },
  data () {
    return {
      loader: null,
      email: '',
      password: '',
      emailErrors: [],
      passwordErrors: [],
      showAlert: false,
    }
  },
  computed: {
    formReady () {
      return !this.emailErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    onsubmit: async function () {
      this.loader = this.$loading.show()

      // Check errors
      this.showAlert = false
      this.emailErrors = this.email ? [] : ['Email is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        this.loader.hide()
        return
      }

      try {
        const payload = {
          username: this.email,
          password: this.password
        }
        // Login a user
        const auth = await axios.post(`${process.env.VUE_APP_API_URL}/api/token`, payload)
        this.$store.commit('setToken', auth.data.access)

        const headers = {
          Authorization: `Bearer ${auth.data.access}`
        }
        // Load profile
        const profile = await axios.get(`${process.env.VUE_APP_API_URL}/api/profile`, { headers })
        // Set auth settings
        this.$store.commit('changeUserName', `${profile.data.user.first_name} ${profile.data.user.last_name}`)
        this.$store.commit('setUID', profile.data.user.uid)
        this.$store.commit('setAccess', profile.data.user.access)

        this.$router.push({ path: this.$route.query.redirect || 'dashboard' })
      } catch (error) {
        this.showAlert = true
      }
      this.loader.hide()
    },
  },
}
</script>
